import React, { useCallback, useEffect, useState } from "react";
import { Button, NavTab, NavTabItem, Snackbar, Tooltip } from "@cimpress/react-components";
import { DatasetIssuesDashboard } from "../../datasets/DatasetIssuesDashboard";
import { AuthContext } from "../../../../context/authContext";
import { ReportAnIssueModule } from "../../../issueTracker/IssueTrackerWrapper";
import { useHistory, useParams } from "react-router-dom";
import ConsumableDatasets from "./ConsumableDatasets";
import MetadataColumns from "../shared/MetadataColumns";
import MetadataDescription from "../shared/MetadataDescription";
import OpenInSnowflakeButton from "../shared/OpenInSnowflakeButton";
import OpenInDataDiscoveryButton from "../shared/OpenInDataDiscoveryButton";
import {
    clearDatasetError,
    clearDatasetStatus,
} from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/pdwDatasetsSlice";
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { useAppDispatch, useAppSelector } from "../../../../store/storeHooks";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import DeletePdwDatasetButton from "./DeletePdwDatasetButton";
import { PdwDatasetGetCollectionItem, PdwDatasetGetItem } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalAPI/dataProductResources";
import { patchPdwDataset } from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/common";
import { DatasetIdToMetadataState, isErrorMetadata, isLoadingMetadata, isDataDiscoveryDatasetMetadata } from "../../../../hooks/useDataDiscoveryMetadata";
import { ErrorDetails } from "../../ErrorDetails";
import { DatasetIdFormatType, translateDatasetId } from "../../../../utils/dataset/datasetId";
import { SliceState } from "@cimpress-technology/data-portal-core/lib/features/common";
import { OutputPortType } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { OutputPortHook } from "../../../../hooks/useOutputPorts";
import { teal } from "@cimpress/react-components/lib/colors";
import { getPublishedPdwDatasetCollection } from "@cimpress-technology/data-portal-core/lib/features/outputPorts/datasets/common";
import TransferOwnershipButton from "../shared/TransferOwnershipButton";
import { getAccountFromCluster } from "../../../../utils/dataset/datasetId";

type TabControlType = {
    description: boolean;
    columns: boolean;
    dataIssues: boolean;
    dataContracts: boolean;
    consumableDatasets: boolean;
};
const buildSingleActiveTab = (
    tabName: keyof TabControlType
): TabControlType => {
    const currentControl: TabControlType = {
        description: false,
        columns: false,
        dataIssues: false,
        dataContracts: false,
        consumableDatasets: false,
    };
    currentControl[tabName] = true;
    return currentControl;
};


const PublishActionButton = (props: {
    accessToken: string,
    dataProductId: string,
    resource: {
        resourceId: string,
        replaceStatus: SliceState,
        published: boolean
    },
    afterAction: () => void
}) => {
    const dispatch = useAppDispatch();
    const actionText = props.resource.published ? 'Remove from Output Ports' : 'Add as Output Port';
    const actionPublished = props.resource.published ? false : true;
    const buttonVariant = {
        'Remove from Output Ports': 'outline-secondary',
        'Add as Output Port': 'primary'
    }[actionText] as 'outline-secondary' | 'dark';
    return <Button disabled={props.resource.replaceStatus === 'loading'} variant={buttonVariant} size='sm' onClick={() => dispatch(patchPdwDataset({
        accessToken: props.accessToken,
        dataProductId: props.dataProductId,
        resourceId: props.resource.resourceId,
        published: actionPublished
    })).then(result => {
        if (result.meta.requestStatus === 'fulfilled') setTimeout(() => props.afterAction(), 1000);
    })}>{props.resource.replaceStatus === 'loading' ? <Spinner size='small' /> : actionText}</Button>;
};

type Props = {
    isSameBusiness: boolean;
    hasDataProductReadAccess: boolean;
    hasDataProductAdminAccess: boolean;
    dataset: PdwDatasetGetItem | PdwDatasetGetCollectionItem;
    getMetadata: (datasetId: string) => Promise<void>;
    datasetIdToMetadataState: DatasetIdToMetadataState;
    pdwDatasetsHook: OutputPortHook<OutputPortType.PDWdataset>;
}
export const MainTabItemContent = (props: Props) => {
    const dispatch = useAppDispatch();
    const { datasetsErrors, datasetsStatus } = useAppSelector(
        (state) => state.resourcesPdwDatasets
    );
    const history = useHistory();
    const [tabControl, setTabControl] = useState<TabControlType>(
        buildSingleActiveTab("description")
    );
    const { dataProductId } = useParams<{ dataProductId: string }>();
    const { accessToken } = React.useContext(AuthContext);
    const { getMetadata, dataset: resource, datasetIdToMetadataState: metadataDictionary } = props;
    const datasetMetadata = metadataDictionary[translateDatasetId(resource.resourceId, DatasetIdFormatType.DataDiscoveryId)];

    const handleLoadMetadata = useCallback((resourceId: string) => {
        getMetadata(resourceId);
    }, [getMetadata]);

    useEffect(() => {
        handleLoadMetadata(resource.resourceId);
    }, [resource, handleLoadMetadata]);

    if (datasetMetadata == null) return null;
    if (isLoadingMetadata(datasetMetadata)) return <div><h3>Loading Metadata</h3> <Spinner size="medium" /></div>;
    const TabControl = (
        <NavTab vertical={false}>
            <NavTabItem active={tabControl.description}>
                <button
                    onClick={() => {
                        setTabControl(buildSingleActiveTab("description"));
                    }}
                    style={{ marginLeft: 0 }}
                >
                    Description
                </button>
            </NavTabItem>
            <NavTabItem active={tabControl.columns}>
                <button
                    onClick={() => {
                        setTabControl(buildSingleActiveTab("columns"));
                    }}
                >
                    Columns
                </button>
            </NavTabItem>
            <NavTabItem active={tabControl.dataIssues}>
                <button
                    onClick={() => {
                        setTabControl(buildSingleActiveTab("dataIssues"));
                    }}
                >
                    Data Issues
                </button>
            </NavTabItem>
            <NavTabItem active={tabControl.consumableDatasets}>
                <button
                    onClick={() => {
                        setTabControl(
                            buildSingleActiveTab("consumableDatasets")
                        );
                    }}
                >
                    Consumable Datasets (Data Contracts)
                </button>
            </NavTabItem>
        </NavTab>
    );



    return (
        <div className="row">
            <Snackbar
                show={Object.values(datasetsStatus[props.dataset.resourceId] || {}).some(status => status === 'failed') === true}
                status="danger"
                delay={10000}
                onHideSnackbar={() => {
                    dispatch(clearDatasetError({ resourceId: props.dataset.resourceId }));
                    dispatch(clearDatasetStatus({ resourceId: props.dataset.resourceId }));
                }}
            >
                {datasetsErrors[props.dataset.resourceId] != null ? (
                    <p>
                        {parseUnknownErrorTypeToErrorMessage(
                            datasetsErrors[props.dataset.resourceId]
                        )}
                    </p>
                ) : (
                    <p>Unknown Error</p>
                )}
            </Snackbar>
            <div className="col-md-12">
                <b>{props.dataset.resourceId}</b>
            </div>
            <div
                className="col-md-12 card-block"
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <div className="" style={{ display: "inline-block" }}>
                    <div style={{ display: "flex", gap: 8 }}>
                        {accessToken && props.hasDataProductAdminAccess && isDataDiscoveryDatasetMetadata(datasetMetadata) && (
                            <TransferOwnershipButton
                                accessToken={accessToken}
                                dataProductId={dataProductId}
                                resourceId={props.dataset.resourceId}
                            />
                        )}
                        {isDataDiscoveryDatasetMetadata(datasetMetadata) && (
                            <OpenInDataDiscoveryButton
                                isSameBusiness={props.isSameBusiness}
                                datasetAccount={getAccountFromCluster(datasetMetadata.cluster)}
                                database={datasetMetadata.database}
                                schema={datasetMetadata.schema}
                                name={datasetMetadata.name}
                            />
                        )}
                        {props.hasDataProductReadAccess === true && isDataDiscoveryDatasetMetadata(datasetMetadata) && (
                            <OpenInSnowflakeButton
                                datasetAccount={getAccountFromCluster(datasetMetadata.cluster)}
                                database={datasetMetadata.database}
                                schema={datasetMetadata.schema}
                                name={datasetMetadata.name}
                                isView={datasetMetadata.isView}
                            />
                        )}
                        {accessToken && props.hasDataProductAdminAccess && (
                            <>
                                <PublishActionButton
                                    accessToken={accessToken}
                                    dataProductId={dataProductId}
                                    resource={{
                                        resourceId: props.dataset.resourceId,
                                        replaceStatus: datasetsStatus[props.dataset.resourceId]?.replace || 'idle',
                                        published: props.dataset.published
                                    }}
                                    afterAction={() => {props.pdwDatasetsHook.abortCurrentRequest();
                                        props.pdwDatasetsHook.loadOutputPorts();
                                        dispatch(
                                        getPublishedPdwDatasetCollection({
                                            accessToken: accessToken,
                                            dataProductId: dataProductId,
                                        })
                                    );}}
                                />
                                <Tooltip
                                    direction="right"
                                    contents={
                                        props.dataset.published ?
                                        <span>Removing a PDW Dataset Resource from Output Ports will remove it from the Output Ports section and it will not be available for sharing.</span>
                                        :
                                        <span>Adding a PDW Dataset Resource as Output Port will make it visible in the Output Ports section and it will be available for sharing.</span>
                                    }
                                >
                                    <IconInformationCircle size="lg" weight="fill" color={teal.base}/>
                                </Tooltip>
                            </>
                        )}
                    </div>
                </div>
                {(datasetsStatus[props.dataset.resourceId]?.remove === "loading")&& (
                    <Spinner size="small" />
                )}
                {accessToken != null && datasetsStatus[props.dataset.resourceId]?.remove !== "loading" && props.hasDataProductAdminAccess === true && (
                    <DeletePdwDatasetButton
                        datasetId={props.dataset.resourceId}
                        accessToken={accessToken}
                        dataProductId={dataProductId}
                    />
                )}
            </div>

            <div className="col-md-12" style={{ marginTop: "10px" }}>
                {TabControl}
            </div>
            <div className="col-md-12">
                <div
                    style={{
                        display: !tabControl.description ? "none" : undefined,
                    }}
                    >
                        <MetadataDescription description={(isDataDiscoveryDatasetMetadata(datasetMetadata) ? datasetMetadata.description : '') || ""} />
                </div>
                <div
                    style={{
                        display: !tabControl.columns ? "none" : undefined,
                    }}
                >
                    <MetadataColumns columns={(isDataDiscoveryDatasetMetadata(datasetMetadata) ? datasetMetadata.columns : []) || []} />
                </div>
                <span
                    style={{
                        display: !tabControl.dataIssues ? "none" : undefined,
                    }}
                >
                    {isDataDiscoveryDatasetMetadata(datasetMetadata) && (
                        <ReportAnIssueModule
                            dataProductId={dataProductId}
                            dataset={datasetMetadata}
                            history={history}
                        />
                    )}
                    <DatasetIssuesDashboard id={props.dataset.resourceId} />
                </span>
                {accessToken && tabControl.consumableDatasets === true && isDataDiscoveryDatasetMetadata(datasetMetadata) && (
                    <ConsumableDatasets
                        accessToken={accessToken}
                        metadata={datasetMetadata}
                        dataset={props.dataset}
                        dataProductId={dataProductId}
                        source="resourcesPdwDatasets"
                    />
                )}
            </div>
            {isErrorMetadata(datasetMetadata) && <div className="col-md-12"><ErrorDetails error={datasetMetadata.error} retryFunction={() => getMetadata(resource.resourceId)} /></div>}
        </div>
    );
};
