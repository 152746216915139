import { Common, Interfaces } from "@cimpress-technology/data-portal-core";
import { DataProductMember } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { DataProductCoamUser } from "./interfaces";

const isProductTeamMember = (canonicalId: string, productTeam: { [index: string]: DataProductMember } | null | undefined) => {
    const key = canonicalId.toLowerCase();
    return productTeam != null
        ? productTeam[key] != null || productTeam[key] != null
        : false;
};

const getLabels = (canonicalId: string, productTeam: { [index: string]: DataProductMember } | null | undefined) => {
    const key = canonicalId.toLowerCase();
    return productTeam != null ? productTeam[key]?.labels || [] : [];
};

export class CoamMembersMapBuilder {
    private membersMap: Record<string, DataProductCoamUser>;

    constructor() {
        this.membersMap = {};
    }

    private addAdmins(adminCoamGroup: Interfaces.Coam.CoamGroupDetail) {
        adminCoamGroup.members.forEach((m) => {
            this.membersMap[m.principal.toLowerCase()] = {
                member: m,
                isAdmin: true,
                labels: [],
                isProductTeamMember: false,
            };
        });
    }

    private addReaders(readCoamGroup: Interfaces.Coam.CoamGroupDetail) {
        readCoamGroup.members.forEach((m) => {
            const key = m.principal.toLowerCase();
            if (!this.membersMap[key]) {
                this.membersMap[key] = {
                    member: m,
                    isAdmin: false,
                    labels: [],
                    isProductTeamMember: false,
                };
            }
        });
    }

    public build(
        adminCoamGroup: Interfaces.Coam.CoamGroupDetail,
        readCoamGroup: Interfaces.Coam.CoamGroupDetail
    ) {
        this.addAdmins(adminCoamGroup);
        this.addReaders(readCoamGroup);
        return this.membersMap;
    }
}

export class CoamMembersMapWithProductTeamComposer {
    private membersMap: Record<string, DataProductCoamUser>;

    constructor(coamMembersMap: Record<string, DataProductCoamUser>) {
        this.membersMap = { ...coamMembersMap }; // Very important, otherwise the State reference stays the same and React does not re-render the mutated object.
    }

    private addProductTeamMetadata(
        productTeam: { [index: string]: DataProductMember } | null | undefined
    ) {
        const mutatedMap: typeof this.membersMap = {};
        Object.keys(this.membersMap).forEach((canonicalId) => {
            const key = canonicalId.toLowerCase();
            mutatedMap[key] = {
                ...this.membersMap[key],
                isProductTeamMember: isProductTeamMember(key, productTeam),
                labels: getLabels(key, productTeam),
            };
        });
        this.membersMap = { ...mutatedMap };
    }

    public compose(
        productTeam: { [index: string]: DataProductMember } | null | undefined
    ) {
        this.addProductTeamMetadata(productTeam);
        return this.membersMap;
    }
}

/**
 * This class creates the illusion of instance COAM Groups adds and removes
 */
export class MembersMapWithPreviousStateComposer {
    private futureMembersMap: Record<string, DataProductCoamUser>;
    private previousMembersMap: Record<string, DataProductCoamUser> | null;
    private supportMembersInfoFromSlice: { [index: string]: DataProductMember } | null | undefined;

    constructor(
        futureMembersMap: Record<string, DataProductCoamUser>,
        previousMembersMap: Record<string, DataProductCoamUser> | null,
        supportMembersFromSlice: { [index: string]: DataProductMember } | null | undefined,
    ) {
        this.futureMembersMap = { ...futureMembersMap };
        this.previousMembersMap = previousMembersMap ? { ...previousMembersMap} : null;
        this.supportMembersInfoFromSlice = supportMembersFromSlice;
    }

    private shouldProceed() {
        if (!this.previousMembersMap) {
            return false;
        }
        if (Object.keys(this.previousMembersMap).length === 0) {
            return false;
        }
        return true;
    }

    /**
     * Because they were `added` but the `computed` future map does not include them (Because it only sees old COAM information)
     * @returns -
     */
    private bringMembersFromPreviousIntoFutureMap() {
        const previousMap = this.previousMembersMap;
        if (previousMap == null ) return;
        const previousMembersKeys = Object.keys(previousMap);
        previousMembersKeys.forEach((key) => {
            if (!this.futureMembersMap[key]) {
                this.futureMembersMap[key] = {
                    ...previousMap[key],
                    isProductTeamMember: isProductTeamMember(key, this.supportMembersInfoFromSlice),
                    labels: getLabels(key, this.supportMembersInfoFromSlice),
                };
            }
        });
    }

    /**
     * Because there were `removed` but the `computed` future map still includes them (Because it only sees old COAM information)
     */
    private removeMembersFromFutureNotInPreviousMap() {
        const previousMap = this.previousMembersMap;
        const previousMembersKeys = Object.keys(previousMap || {});
        Object.keys(this.futureMembersMap).forEach((key) => {
            if (!previousMembersKeys.includes(key)) {
                delete this.futureMembersMap[key];
            }
        });
    }

    public compose() {
        if (!this.shouldProceed()) {
            return this.futureMembersMap;
        }
        this.bringMembersFromPreviousIntoFutureMap();
        this.removeMembersFromFutureNotInPreviousMap();
        return this.futureMembersMap;
    }
}

export const getMemberListWithoutClients = (
    membersMap: Record<string, DataProductCoamUser>
) => {
    return Array.from(Object.values(membersMap)).filter(
        (m) =>
            !m.member.principal.startsWith(
                Common.config.DATA_PORTAL_API_AUTH0_CLIENT
            ) &&
            !m.member.principal.startsWith(
                Common.DATA_PORTAL_DEV__API_AUTH0_CLIENT
            )
    );
};
