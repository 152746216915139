import React from 'react';
import {
    IconCloudUpload,
    IconWarehousePackages,
    IconAnalyticsGraph,
    IconImageFileEdit,
    IconDiscount,
    IconGaugeDashboard,
    IconShareAlt
} from '@cimpress-technology/react-streamline-icons/lib';
import { DomainSection } from './DomainSection';


export const ServicesAndTools: React.FC = () => {

    const domains = [
        {
            name: 'Ingesting Data',
            desc: 'Data Platform provides a variety of ways to ingest data into the data platform based on data type - event-based as new data arrives or small batch of JSON data.',
            icon: <IconCloudUpload size='3x' />,
            documentationLink: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/8032325660/Step+1+Ingesting+data',
            services: [
                {
                    name: 'River',
                    description: 'Self-service and flexible way to send event-based JSON to the data platform.',
                    link: "https://console.river.cimpress.io/?utm_source=cimba_home&utm_medium=header&utm_campaign=services"
                }
            ]
        },
        {
            name: 'Documenting Data',
            desc: 'Data Platform supports a web-based platform for data cataloging and discovery for Cimpress teams and businesses.',
            icon: <IconImageFileEdit size='3x' />,
            documentationLink: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/7997164203/Step+2+Documenting+data',
            services: [
                {
                    name: 'Data Discovery',
                    description: 'Discover the insights about data, including data cataloging, usage, propagation, and more.',
                    link: "https://data-discovery.cimpress.io/?utm_source=cimba_home&utm_medium=header&utm_campaign=services"
                },
                {
                    name: 'Data Discovery API',
                    description: 'Document your data programmatically using Data Discovery API.',
                    link: "https://developer.cimpress.io/apis/business-analytics/data-discovery-metadata-service"
                }
            ]
        },
        {
            name: 'Interacting with Data',
            desc: 'There are different methods of quering data in Private Data Warehouses avaliable for data platform users.',
            icon: <IconAnalyticsGraph size='3x' />,
            documentationLink: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/7986873366/Step+3+Interacting+with+data',
            services: [
                {
                    name: 'Private Data Warehouse',
                    description: 'Store, access and query data in a cloud-based elastic Private Data Warehouse (PDW) based on Snowflake.',
                    link: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/402849966/Private+Data+Warehouse'
                },
                {
                    name: 'Databricks',
                    description: 'Query PDW using Databricks.',
                    link: "https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/2035025860/Databricks"
                },
                {
                    name: 'Looker',
                    description: 'Explore data without having to write SQL, create and distribute beautiful and modern reports and dashboards.',
                    link: "https://looker.cimba.cimpress.io/?utm_source=cimba_home&utm_medium=header&utm_campaign=services"
                },
                {
                    name: 'Nala',
                    description: 'Embed Looker dashboards, explores or looks into your web applications.',
                    link: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/442336972/Nala'
                },
                {
                    name: 'Transformer',
                    description: 'Generate SQL and Looker views from a sample JSON from your table/stream.',
                    link: 'https://console.transformer.cimpress.io'
                }
            ]
        },
        {
            name: 'Giving Access to Data',
            desc: 'Data owners can provide access to their data to others within the same PDW or by publishing data outside PDW.',
            icon: <IconShareAlt size='3x' />,
            documentationLink: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/7997164033/Step+4+Giving+access+to+data',
            services: [
                {
                    name: 'PDW Access Management',
                    description: 'Manage the access to your data.',
                    link: "https://manage-access.pdw.cimpress.io/"
                }
            ]
        },
        {
            name: 'Creating Data Products',
            desc: 'Data Platform allows to create, manage, publish and access data products, developed by Cimpress teams and businesses.',
            icon: <IconWarehousePackages size='3x' />,
            documentationLink: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/7997164001/Step+5+Creating+data+products',
            services: [
                {
                    name: 'Data Portal',
                    description: 'Create, publish and access data products from different domains in Data Portal.',
                    link: "https://data.cimpress.io/"
                },
                {
                    name: 'AWS Central Storage Account',
                    shortName: 'AWS Central Storage',
                    description: 'Store private and public data for your data products by leveraging AWS S3 and AWS Glue services.',
                    link: "https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/7969505892/AWS+Central+Storage+Account"
                },
                {
                    name: 'Databricks',
                    description: 'Use Apache Spark clusters on-demand as the data processing framework.',
                    link: "https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/2035025860/Databricks"
                },
                {
                    name: 'Matillion',
                    description: 'Run and schedule data transformation jobs for your data products easily.',
                    link: "https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/7923663598/Getting+Started+with+Matillion"
                },
                {
                    name: 'Conductor (Airflow)',
                    shortName: 'Conductor',
                    description: 'Conductor is part of our platform data product toolkit offering which provides orchestration and powered by Airflow.',
                    link: "https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/14845968844/Conductor+Airflow"
                }
            ]
        },
        {
            name: 'Understanding Usage Patterns',
            desc: 'Data platform empowers users to manage and monitor the whole data lifecycle, including ingestion, processing, and consumption.',
            documentationLink: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/8014660830/Step+6+Understanding+usage+patterns',
            icon: <IconGaugeDashboard size='3x' />,
            services: [
                {
                    name: 'Data lineage, usage and performance - SIFT',
                    shortName: 'SIFT',
                    description: 'Browse the usage of particular tables, dive into the query history and credit usage.',
                    link: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/617644178/Data+lineage+and+performance'
                },
                {
                    name: 'Data issues recommendations - DMON',
                    shortName: 'DMON',
                    description: 'Detect PDW performance problems and apply suggestions and recommendations.',
                    link: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/1941736809/Data+issues+recommendations+via+emails+DMON'
                }
            ]
        },
        {
            name: 'Optimising Data Efficiency and Cost',
            desc: 'Data owners are enabled to analyze cost and performance efficiency for the data stored in the PDW.',
            documentationLink: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/8032292604/Step+7+Optimising+data+efficiency+and+cost',
            icon: <IconDiscount size='3x' />,
            services: [
                {
                    name: 'Snowflake Performance Insights',
                    shortName: 'Performance Insights',
                    description: 'Understand various aspects of how the datasets respond to the queries.',
                    link: "https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/568820027"
                },
                {
                    name: 'Resource Usage & Cost',
                    description: 'Detailed cost breakdown for users to understand the magnitude of engagement of different users/tribes with our data in terms of cost.',
                    link: "https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/699171271/Resource+usage+and+cost"
                }
            ]
        },
    ];

    return <div className='row'>
        <div className='col-md-offset-1 col-md-10'>
            <div className='services-and-tools'>

                <div style={{ textAlign: 'left' }}>
                    <h2>Data Services & Tools</h2>
                    <small>Start working with data and create data products using the tools that the Data Platform offers.</small>
                </div>
                <hr />

                {domains.map((d) => <div key={d.name}>
                    <br/>
                    <DomainSection dataDomain={d} />
                </div>)}

                <br />

                <div>

                    <h2>Other helpful resources</h2>
                    <ul>
                        <li><a href='https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/4015695243/Direction+Data+Products+Creating+Stable+Data+Sets' target='__blank' rel="noopener noreferrer">Direction Data Products: Creating Stable Data Sets</a></li>
                        <li><a href='https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/330958920/GDPR+and+General+Privacy+and+Regulation+Guidelines' target='__blank' rel="noopener noreferrer">GDPR and General Privacy and Regulation Guidelines</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>;
};
