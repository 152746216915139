import React, { useState, useEffect } from 'react';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { AuthContext } from '../../../context/authContext';
import './DatasetIssuesDashboard.scss';
import { NalaFilter } from '@cimpress-technology/data-portal-core/lib/clients/nala';
import { Nala } from '@cimpress-technology/data-portal-core/lib/clients';
import ErrorDetails from '@cimpress-technology/data-portal-core/lib/components/ErrorDetails';

export const DatasetIssuesDashboard: React.FC<{ id: string }> = ({ id }) => {
    const { accessToken } = React.useContext(AuthContext);
    const [embedLink, setEmbedLink] = useState(undefined as string | undefined);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        setEmbedLink(undefined);
        const costReportConfigurationId = 'dac1e710-345c-4260-afd8-6474323d7c9b';
        const filters: NalaFilter[] = [
            {
                "filter": "Resource Key",
                "filterValue": `${id.toUpperCase()}`
            },
            {
            "filter": "Resource Type",
            "filterValue": "pdwDataset"
            }
        ];
        Nala.generateEmbedLink(accessToken, costReportConfigurationId, filters, {}).then(link => {
            setEmbedLink(link);
        }).catch(err => {
            setError(err);
        });
    }, [accessToken, id]);

    if (error) {
        return <ErrorDetails title='It was not possible to generate the embedded content' error={error} />;
    }

    if (!embedLink) {
        return (
            <div style={{ textAlign: 'center' }}>
                <Spinner />
                <span>Loading dashboard...</span>
            </div>
        );
    }


    return <div className="dataset-issues-dashboard">
        <br/>
        <h3> DMON </h3>
        <hr/>
        <iframe src={embedLink} />
    </div>;
};
