import React, { ReactNode, useMemo, useState } from "react";
import {
    TextField,
    Table,
    Alert,
    Drawer,
    Button,
    colors,
} from "@cimpress/react-components";
import {
    DataProduct,
} from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import {
    DataPortalAPI,
    Interfaces,
} from "@cimpress-technology/data-portal-core";
import { useAppDispatch, useAppSelector } from "../../../../../store/storeHooks";
import { setEditMode } from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/pdwDatasetsSlice";
import { IconCheck } from "@cimpress-technology/react-streamline-icons/lib";
import { AddPdwDatasetButton } from "../AddPdwDatasetButton";
import { ModalLink } from "./ModalLink";

function filterDatasetMatchingSearch(
    datasets: Interfaces.DataPortalAPI.UserOwnedDomainDataset[],
    searchText: string,
    dataProductId: string
): Interfaces.DataPortalAPI.UserOwnedDomainDataset[] {
    // The function will filter from list of dataset user owns the one that user can add to the Data product
    // It will also consider search text to filter content based on search
    let filteredDatasets = [...datasets];
    if (searchText) {
        const lowercaseSearch = searchText.toLowerCase();
        filteredDatasets = filteredDatasets.filter(
            (d) =>
                d.name.toLowerCase().includes(lowercaseSearch) ||
                d.database.toLowerCase().includes(lowercaseSearch) ||
                d.schema.toLowerCase().includes(lowercaseSearch)
        );
    }
    filteredDatasets.sort((a, b) => a.name.localeCompare(b.name));

    // Lets move data product used in other published data product at the end of the table
    const datasetsUsedInOtherDataProduct =
        [] as Interfaces.DataPortalAPI.UserOwnedDomainDataset[];
    const datasetsUnusedInOtherProducts =
        [] as Interfaces.DataPortalAPI.UserOwnedDomainDataset[];
    filteredDatasets.forEach((dataset) => {
        if (
            dataset.useInDataProduct?.dataProductId &&
            dataset.useInDataProduct.dataProductId !== dataProductId
        ) {
            datasetsUsedInOtherDataProduct.push(dataset);
        } else {
            datasetsUnusedInOtherProducts.push(dataset);
        }
    });

    return [
        ...datasetsUnusedInOtherProducts,
        ...datasetsUsedInOtherDataProduct,
    ];
}

export const UserOwnedDatasetsDrawer: React.FC<{
    dataProduct: DataProduct;
    domain: DataPortalAPI.Domain;
    includedDatasetIds: string[];
    hasDataProductReadAccess: boolean;
    hasDataProductAdminAccess: boolean;
}> = ({
    dataProduct,
    domain,
    includedDatasetIds,
    hasDataProductAdminAccess,
    hasDataProductReadAccess,
}) => {
    // Drawer size to display
    const dispatch = useAppDispatch();
    const drawerSize = 0.55;

    const { userOwnedDatasetsState, editMode, pdwDatasetCollectionState } = useAppSelector(
        (state) => state.resourcesPdwDatasets
    );
    const [searchText, setSearchText] = useState("");

    const filteredDatasets = useMemo(() => {
        if (userOwnedDatasetsState.status === "succeeded") {
            return filterDatasetMatchingSearch(
                userOwnedDatasetsState.data || [],
                searchText,
                dataProduct.dataProductId
            );
        }
        return [];
    }, [userOwnedDatasetsState, searchText, dataProduct.dataProductId]);

    const databaseColumns = [
        {
            Header: "Action",
            accessor: ".",
            Cell: (row: { original: Interfaces.DataPortalAPI.UserOwnedDomainDataset } ) => {
                return (
                    <div style={{ marginLeft: "-15px" }}>
                        {!includedDatasetIds.includes(row.original.id) ? (
                            <AddPdwDatasetButton
                                dataProductId={dataProduct.dataProductId}
                                usedInThisDataProductId={
                                    (row.original.useInDataProduct &&
                                        row.original.useInDataProduct
                                            .dataProductId) ||
                                    null
                                }
                                datasetId={row.original.id}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                <IconCheck
                                    weight="fill"
                                    style={{ color: colors.slate }}
                                />
                            </div>
                        )}
                    </div>
                ) as ReactNode;
            },
        },
        {
            Header: "Object",
            accessor: "name",
            Cell: (row: { original: Interfaces.DataPortalAPI.UserOwnedDomainDataset } ) => {
                return (
                    <ModalLink
                        datasetName={row.original.name}
                        datasetId={row.original.id}
                        hasDataProductReadAccess={hasDataProductReadAccess}
                        hasDataProductAdminAccess={hasDataProductAdminAccess}
                    />
                ) as ReactNode;
            },
        },
        {
            Header: "Database",
            accessor: "database",
        },
        {
            Header: "Schema",
            accessor: "schema",
        },
        {
            Header: "PDW",
            accessor: "cluster",
            Cell: (row) => {
                return row.original.cluster.split(".")[0];
            },
        },
        { Header: "PDW Ownership Role", accessor: "ownershipRole" },
    ];

    const datasetInfoAlert = (
        <Alert
            status="info"
            dismissible={true}
            message={
                <span>
                    The Datasets included:
                    <ul>
                        <li>
                            Appear in the list after up to <b>5 minutes</b> of
                            being created in Snowflake.
                        </li>
                        <li>
                            Belong to the <b>Domain</b> of your Data Product,
                            marked in{" "}
                            <a
                                href="https://data-discovery.cimpress.io/"
                                target="__blank"
                                rel="noopener noreferrer"
                            >
                                Data Discovery
                            </a>
                            .
                        </li>
                        <li>
                            Are owned by a role that you have ownership over in
                            Snowflake or by the role{" "}
                            <a href={`https://console.river.cimpress.io/`}>
                                RIVER
                            </a>{" "}
                            where you have admin permissions on the river stream
                            to access the Dataset.
                        </li>
                    </ul>
                </span>
            }
        />
    );

    const onClose = () => {
        dispatch(setEditMode(false));
    };

    return (
        <>
            <Button
                className="pull-right"
                variant="primary"
                disabled={pdwDatasetCollectionState.status === 'loading'}
                onClick={() => {
                    dispatch(setEditMode(true));
                }}
            >
                + Add Datasets
            </Button>
            <Drawer
                show={editMode}
                onRequestHide={() => onClose()}
                size={drawerSize}
                closeOnClickOutside={true}
                footer={
                    <div className="col-xs-12">
                        <Button
                            className="pull-right"
                            onClick={() => onClose()}
                        >
                            Close
                        </Button>
                    </div>
                }
            >
                {userOwnedDatasetsState.status === "idle" && (
                    <>
                        <p>Nothing is happening.</p>
                    </>
                )}
                {userOwnedDatasetsState.status === "loading" && (
                    <>
                        <Spinner />
                        <p>Loading datasets that you own...</p>
                    </>
                )}
                {userOwnedDatasetsState.status === "succeeded" && (
                    <>
                        <div
                            className="row"
                            style={{ marginTop: -20, paddingBottom: 10 }}
                        >
                            <div className="col-md-12">{datasetInfoAlert}</div>
                        </div>
                        <div>
                            <div>
                                <div className="col-xs-7 row">
                                    <h2 style={{ margin: 0, paddingLeft: 0 }}>
                                        My Datasets from <b>{domain?.name}</b>{" "}
                                        Domain
                                    </h2>
                                    <small style={{ color: "grey" }}>
                                        sourced from{" "}
                                        <a
                                            href="https://data-discovery.cimpress.io"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Data Discovery
                                        </a>
                                    </small>
                                </div>
                                <TextField
                                    className="col-xs-5 pull-right"
                                    value={searchText}
                                    placeholder={
                                        "Search for database schema or object"
                                    }
                                    helpText={`${filteredDatasets.length} Datasets found`}
                                    onChange={(e) => {
                                        setSearchText(e.target.value || "");
                                    }}
                                    style={{ margin: 0, padding: 0 }}
                                    disabled={false}
                                />
                            </div>
                            <Table
                                hasHover={true}
                                data={filteredDatasets}
                                columns={databaseColumns}
                                noDataText={
                                    searchText != ""
                                        ? "No datasets available matching the search"
                                        : "No datasets available for this domain."
                                }
                            />
                        </div>
                    </>
                )}
            </Drawer>
        </>
    );
};
