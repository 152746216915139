import React, { useState } from "react";
import {
    Button,
    Label,
    Modal,
    Select,
    Snackbar,
    colors,
} from "@cimpress/react-components";
import {
    DataPortalAPI,
    Interfaces,
} from "@cimpress-technology/data-portal-core";
import { DeleteUserButton } from "./DeleteUserButton";
import {
    ValidAccountId,
    getTitlesAsSelectOptions,
} from "../../../../../../interfaces/productTeamTitles";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../../store/storeHooks";
import {
    fetchDataProductMembers,
    updateDataProductMemberLabels,
} from "@cimpress-technology/data-portal-core/lib/features/members/common";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import IconPencilCircle from "@cimpress-technology/react-streamline-icons/lib/IconPencilCircle";
import { cleanOperationStatusAndErrors } from "@cimpress-technology/data-portal-core/lib/features/members/membersSlice";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import { renderError } from "../../../../../shared/Render";
import { getDefaultAvatar } from "../../../../../../common";

export interface DataProductCoamUser {
    member: Interfaces.Coam.CoamGroupMember;
    isAdmin: boolean;
    labels: string[];
    isProductTeamMember: boolean;
}

interface FormState {
    titleToUpdate: string | null;
    selectedTitle: string | null;
    existingLabels: string[];
    showModal: boolean;
    validationError: Error | null;
}

interface InitialFormState extends FormState {
    state: "Initial";
    titleToUpdate: null;
    selectedTitle: null;
    existingLabels: string[];
    showModal: false;
    validationError: null;
}

interface UpdateFormState extends FormState {
    state: "Update";
    titleToUpdate: string;
    selectedTitle: string | null;
    existingLabels: string[];
    showModal: true;
    validationError: null;
}

interface UpdatingFormState extends FormState {
    state: "Updating";
    titleToUpdate: string;
    selectedTitle: string | null;
    existingLabels: string[];
    showModal: true;
    validationError: null;
}

interface ValidationErrorFormState extends FormState {
    state: "ValidationError";
    titleToUpdate: string;
    selectedTitle: null;
    existingLabels: string[];
    showModal: true;
    validationError: Error;
}

interface ReadyToSubmitFormState extends FormState {
    state: "ReadyToSubmit";
    titleToUpdate: string;
    selectedTitle: string;
    existingLabels: string[];
    showModal: true;
    validationError: null;
}

const initialState: InitialFormState = {
    state: "Initial",
    titleToUpdate: null,
    selectedTitle: null,
    existingLabels: [],
    showModal: false,
    validationError: null,
};

type Props = {
    label: string;
    memberTitles: string[];
    setParentFormState: React.Dispatch<
        React.SetStateAction<
            | InitialFormState
            | UpdateFormState
            | UpdatingFormState
            | ValidationErrorFormState
            | ReadyToSubmitFormState
        >
    >;
};

const EditableLabelComponent = (props: Props) => {
    const [hoverLabel, setHoverLabel] = useState<boolean>(false);
    return (
        <div
            onClick={() =>
                props.setParentFormState((prevState) => ({
                    ...prevState,
                    state: "Update",
                    titleToUpdate: props.label,
                    showModal: true,
                    existingLabels: props.memberTitles,
                    validationError: null,
                }))
            }
            onMouseEnter={() => setHoverLabel(true)}
            onMouseLeave={() => setHoverLabel(false)}
            style={{
                marginLeft: 6,
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                position: "relative",
            }}
        >
            <Label text={props.label} status="info" />
            {hoverLabel && (
                <IconPencilCircle
                    style={{
                        position: "absolute",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        top: "0",
                        right: "-2",
                    }}
                    color={colors.info.darker}
                    weight="fill"
                />
            )}
        </div>
    );
};

export const MemberTableRow: React.FC<{
    accessToken: string;
    dataProductId: string;
    memberInfo: DataProductCoamUser;
    canManage: boolean;
    onUserDeleted(key: string): void;
    disableDelete: boolean;
}> = ({
    accessToken,
    dataProductId,
    memberInfo,
    canManage,
    onUserDeleted,
    disableDelete
}) => {
    const dispatch = useAppDispatch();
    const { fieldError } = useAppSelector((state) => state.members);
    const { isAdmin, member } = memberInfo;
    const [formState, setFormState] = useState<
        | InitialFormState
        | UpdateFormState
        | UpdatingFormState
        | ValidationErrorFormState
        | ReadyToSubmitFormState
    >(initialState);

    const resetState = () => setFormState(initialState);

    const submitUpdate = () => {
        if (formState.state !== "ReadyToSubmit") {
            setFormState((prevState) => ({
                ...prevState,
                state: "ValidationError",
                validationError: new Error(
                    "Invalid information provided. You must select a title to update and select a title as the new value."
                ),
                titleToUpdate: prevState.titleToUpdate ?? "",
                selectedTitle: null,
                showModal: true,
            }));
            return;
        }
        setFormState((prevState) => ({
            ...prevState,
            state: "Updating",
            titleToUpdate: prevState.titleToUpdate ?? "",
            validationError: null,
            showModal: true,
        }));
        const titles = new Set(formState.existingLabels || []);
        titles.delete(formState.titleToUpdate);
        dispatch(
            updateDataProductMemberLabels({
                accessToken,
                dataProductId,
                canonicalId: member.principal,
                labels: [formState.selectedTitle].concat(Array.from(titles)),
            })
        )
        .then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
                resetState();
            }
            dispatch(
                fetchDataProductMembers({
                    accessToken,
                    dataProductId,
                })
            );
        });

    };

    return (
        <div className="row">
            <Snackbar
                show={fieldError[member.principal] != null}
                status="danger"
                delay={10000}
                onHideSnackbar={() => dispatch(cleanOperationStatusAndErrors())}
            >
                {fieldError[member.principal] != null &&
                    parseUnknownErrorTypeToErrorMessage(
                        fieldError[member.principal]
                    )}
            </Snackbar>
            <Modal
                show={formState.showModal}
                title="Update User Information"
                className="cimpress-modal-fix"
                onRequestHide={resetState}
                closeButton={formState.state !== "Updating"}
                size="lg"
                footer={
                    <div>
                        {formState.state === "ValidationError" &&
                            renderError(formState.validationError.message)}
                        <Button
                            disabled={formState.state === "Updating"}
                            onClick={resetState}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={formState.state !== "ReadyToSubmit"}
                            variant="primary"
                            onClick={submitUpdate}
                        >
                            {formState.state === "Updating" ? (
                                <Spinner size="small" />
                            ) : (
                                "Update"
                            )}
                        </Button>
                    </div>
                }
            >
                <div>
                    <h3>
                        Modify the title <b>{formState?.titleToUpdate}</b> for
                        the Product Team member {member.profile?.name}
                    </h3>
                </div>
                <Select
                    label="Select a title"
                    options={getTitlesAsSelectOptions(
                        member.account_id as ValidAccountId
                    )}
                    isDisabled={formState.state === "Updating"}
                    onChange={(e) => {
                        if (e?.value) {
                            setFormState((prevState) => ({
                                ...prevState,
                                state: "ReadyToSubmit",
                                titleToUpdate: prevState.titleToUpdate || "",
                                selectedTitle: e.value,
                                showModal: true,
                                validationError: null,
                            }));
                        }
                    }}
                />
            </Modal>
            <div className="col-md-6">

                    <>
                        <img
                            src={member.profile?.picture || getDefaultAvatar(member.principal)}
                            height={32}
                            width={32}
                            style={{ borderRadius: "50%" }}
                        />
                        &nbsp;&nbsp;
                        {!member.is_client ? (
                            `${member.profile?.given_name} ${member.profile?.family_name}`
                        ) : (
                            member.principal
                        )}
                    </>

            </div>
            <div className="col-md-6">
                <div
                    style={{
                        float: "right",
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    {memberInfo.labels.map((label) => (
                        <EditableLabelComponent
                            key={label}
                            label={label}
                            memberTitles={memberInfo.labels}
                            setParentFormState={setFormState}
                        />
                    ))}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Label
                            text={isAdmin ? "Admin" : "Reader"}
                            status={isAdmin ? "success" : "warning"}
                            style={{ marginLeft: 6 }}
                        />
                    </div>

                    {canManage ? (
                        <div style={{ marginLeft: 6 }}>
                            <DeleteUserButton
                                accessToken={accessToken}
                                userInfo={{
                                    canonicalPrincipal: member.principal,
                                    role: memberInfo.isAdmin
                                    ? DataPortalAPI.DataProductMemberRole
                                          .Admins
                                    : DataPortalAPI.DataProductMemberRole
                                          .Readers,
                                    isProductTeamMember: memberInfo.isProductTeamMember
                                }}
                                dataProductId={dataProductId}
                                onUserDeleted={onUserDeleted}
                                disableDelete={disableDelete}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
