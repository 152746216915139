import { Snackbar, Toggle , Modal, Button} from '@cimpress/react-components';
import React, { useState, useEffect } from 'react';
import { patchDataProductAttribute } from '@cimpress-technology/data-portal-core/lib/clients/dataPortalApi';
import { AuthContext } from '../../../../../context/authContext';
import { ResourceControlModalMessage } from '../../../../../interfaces/resourceControl';
import axios from 'axios';

export const DataProductResourceControlled: React.FC<{ dataProductId: string,isResourceControlled: boolean, isDataProductAdmin: boolean }> = ({ dataProductId,isResourceControlled, isDataProductAdmin }) => {

    const { accessToken } = React.useContext(AuthContext);
    const [errorUpdating, setErrorUpdating] = useState(undefined as string | undefined);
    const [changingResourceControl, setChangingResourceControl] = useState(false);
    const [resourceControl, setResourceControl] = useState<boolean>(isResourceControlled);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState<ResourceControlModalMessage | null>(null);
    const changeResourceControl = async (dataProductId: string, resourceControl: boolean) => {
        setChangingResourceControl(true);
        setErrorUpdating(undefined);
        try {
            accessToken ? await patchDataProductAttribute(accessToken, dataProductId, 'isResourceControlled', !resourceControl) : null;
            setResourceControl(!resourceControl);
        } catch (error) {
            axios.isAxiosError(error) ? setErrorUpdating(error.response?.data?.message || error.response?.statusText || error.message) : setErrorUpdating((error as Error).message);
        }
        setChangingResourceControl(false);
        setShowModal(false);
    };

    const modalContentOnEnable: ResourceControlModalMessage = {
        description: "By converting your data product to a resource-controlled data product, you authorize the CT Data Platform to manage and operate on your data product resources. Once accepted, the CT Data Platform may automatically initiate and execute operations without further user or client interaction, as directed by the data product admin(s), for processing, transformation, and management.",
        callToAction: "CT Data Platform will ensure secure, compliant, and optimized handling of your resources throughout these processes.",
        cancelButtonText: "Decline",
        acceptButtonText: "Accept"
    };
    
    const modalContentOnDisable: ResourceControlModalMessage = {
        description: "Removing resource control from your data product may cause a failure in the process that is set with a resource-controlled approach.",
        callToAction: "Do you want to continue?",
        cancelButtonText: "Cancel",
        acceptButtonText: "Continue"
    };


    function modalManager(resourceControl: boolean): ResourceControlModalMessage {
        return resourceControl ? modalContentOnDisable : modalContentOnEnable;
    }

    const handleToggle = (resourceControl: boolean) => {
        const message = modalManager(resourceControl);
        setModalMessage(message); 
    };
    
    useEffect(() => {
        if (modalMessage !== null) {
          setShowModal(true); 
        }
    }, [modalMessage]);
    
      

    return <>
        <div>  
                <Modal
                    show={showModal}
                    title={"Confirmation Required: Resource-Controlled Data Product Operations"}
                    onRequestHide={() => setShowModal(false)}
                    closeButton={changingResourceControl !== true}
                    footer={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                size="sm"
                                disabled={changingResourceControl === true}
                                onClick={()=> setShowModal(false)}
                            >

                                {modalMessage && (
                                    modalMessage.cancelButtonText
                                )}
                             
                            </Button>
                            <Button
                                size="sm"
                                variant='primary'
                                disabled={changingResourceControl === true}
                                onClick= {()=> changeResourceControl(dataProductId, resourceControl)}
                            >
                                {modalMessage && (
                                    modalMessage.acceptButtonText
                                )}
                            </Button>
                        </div>
                    }
                >
                    <div>
                        {modalMessage && (
                            <p>
                            {modalMessage.description}
                         <br /><br />
                            <b>{modalMessage.callToAction}</b>
                        </p>
                        )}
                    </div>
                </Modal>

                <Toggle
                    on={resourceControl === true}
                    size="sm"
                    disabled={(!isDataProductAdmin) || changingResourceControl}
                    onClick={()=> handleToggle(resourceControl)}
                />

                
        </div>
        <Snackbar status='danger' show={!!errorUpdating} delay={4000} onHideSnackbar={() => setErrorUpdating(undefined)} >
            <div>{errorUpdating ? errorUpdating : 'here'}</div>
        </Snackbar>
    </>;
};
